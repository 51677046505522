import React, { HTMLProps, useState } from "react";
import { Icon } from "../Icon";
import { Popover } from "../Popover";

export interface SearchBarDropdownProps extends HTMLProps<HTMLInputElement> {
  type: "small" | "medium" | "large";
  options: string[];
  onOptionSelect?: (selectedOption: string) => unknown;
}

export const SearchBarDropdown = ({
  type,
  options,
  onOptionSelect,
  className,
  ...props
}: SearchBarDropdownProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select");
  return (
    <div className="relative bg-blue-grey-2 rounded-full">
      <Icon
        name="search"
        color="#7C7F93"
        className={`absolute left-2 ${
          type === "small"
            ? "top-[5px]"
            : type === "medium"
            ? "top-2.5"
            : "top-3.5"
        }`}
        size={type === "small" ? "12" : "16"}
      />
      <input
        {...props}
        placeholder="search"
        className={` outline-0 searchbar-dropdown searchbar-dropdown-${type} ${className ??
          ""} border-0 bg-transparent `}
      />
      <Popover
        positions={["bottom"]}
        onClickOutside={() => setShowPopup(false)}
        isOpen={showPopup}
        content={
          <div className="bg-white shadow-card rounded-xl p-1">
            <ul className="p-0 m-0 list-none">
              {options.map((option, index) => (
                <li
                  key={option + index}
                  onClick={() => {
                    setSelectedOption(option);
                    onOptionSelect && onOptionSelect(option);
                    setShowPopup(false);
                  }}
                  className="py-2 px-2.5  font-lato-bold text-tiny text-black cursor-pointer"
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <div
          className={`searchbar-dropdown-select-${type} cursor-pointer font-lato-regular text-grey-3 flex items-center absolute`}
          onClick={() => setShowPopup(true)}
        >
          {selectedOption}{" "}
          {showPopup ? (
            <Icon name={"chevron-up"} className="ml-2" size="16" />
          ) : (
            <Icon name={"chevron-down"} className="ml-2" size="16" />
          )}
        </div>
      </Popover>
    </div>
  );
};
