// TODO: need to add dynamic type for option

import React, { useState } from "react";
import Select, {
  Props,
  components,
  DropdownIndicatorProps,
  PlaceholderProps,
  GroupBase
} from "react-select";
import { Popover } from "react-tiny-popover";
import { Icon } from "../Icon";
import { Checkbox } from "../Checkbox";

export interface SearchBarProps {
  size: "small" | "medium" | "large";
  filters: string[];
}

export const SearchBar = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  size,
  options,
  filters,
  className,
  ...props
}: Props<Option, IsMulti, Group> & SearchBarProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const CustomDropdown = (
    dropdownProps: DropdownIndicatorProps<Option, IsMulti, Group>
  ) => {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        {selectedFilters.length > 0 ? (
          <button
            type="button"
            className={`absolute border border-solid right-2 flex items-center bg-blue-grey-4 rounded-full clear-filter-button-${size} text-black font-lato-bold`}
            onClick={() => setSelectedFilters([])}
          >
            {" "}
            <div>Clear Filters</div>
            <Icon
              name="cross"
              size={size === "small" ? "10" : "16"}
              color="#424452"
              className={`filter-close-icon-${size}`}
            />
          </button>
        ) : null}
        {filters.length > 0 ? (
          <Popover
            isOpen={showFilter}
            containerClassName="z-[100]"
            positions={["bottom"]} // if you'd like, you can limit the positions
            padding={10} // adjust padding here!
            onClickOutside={() => setShowFilter(false)} // handle click events outside of the popover/target here!
            content={() => (
              <div className="bg-white drop-shadow-general-shadow p-2 rounded-2xl">
                <ul className="p-0 m-0 list-none">
                  {filters.map(filterItem => (
                    <li
                      className={`filter-option-${size}`}
                      key={filterItem}
                      onClick={() => {
                        const existingFilters = [...selectedFilters];
                        const filterIndex = existingFilters.findIndex(
                          item => item === filterItem
                        );
                        if (filterIndex !== -1) {
                          existingFilters.splice(filterIndex, 1);
                        } else {
                          existingFilters.push(filterItem);
                        }
                        setSelectedFilters(existingFilters);
                      }}
                    >
                      <Checkbox
                        className="mr-3"
                        type="small"
                        checked={selectedFilters.includes(filterItem)}
                      />
                      {filterItem}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          >
            <div>
              <Icon
                size={size === "small" ? "12" : "16"}
                name="filter"
                onClick={() => setShowFilter(true)}
                className="cursor-pointer"
                color="#7C7F93"
              />
            </div>
          </Popover>
        ) : null}
      </components.DropdownIndicator>
    );
  };
  const CustomPlaceholder = ({
    children,
    ...remaining
  }: PlaceholderProps<Option, IsMulti, Group>) => {
    return (
      <components.Placeholder {...remaining}>
        <Icon
          name="search"
          color="#7C7F93"
          className={`absolute left-0 ${
            size === "small"
              ? "top-[5px]"
              : size === "medium"
              ? "top-[1px]"
              : "top-1"
          }`}
          size={size === "small" ? "12" : "16"}
        />
        {children}
      </components.Placeholder>
    );
  };
  return (
    <Select
      {...props}
      className={`dropdown dropdown-${size} search-dropdown ${
        selectedFilters.length ? "has-filters" : ""
      } ${className || ""}`}
      options={options}
      classNamePrefix="tango"
      menuIsOpen={searchQuery.length > 0}
      onInputChange={newValue => setSearchQuery(newValue)}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: CustomDropdown,
        Placeholder: CustomPlaceholder
      }}
    />
  );
};

export default SearchBar;
