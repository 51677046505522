import React, { useState } from "react";
import { ActionMeta, MultiValue, SingleValue } from "react-select";

import DateRangePicker, {
  DateRangePickerProps
} from "@wojtekmaj/react-daterange-picker";
import { Icon } from "../Icon";
import { Dropdown } from "../Dropdown";

interface OptionType {
  label: string;
  value: string;
}
export interface DateRangeProps extends DateRangePickerProps {
  size: "large" | "small";
  onSelect: (
    newValue: MultiValue<OptionType> | SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => void;
  hideRangeOptions?: boolean;
  customRangeOptions?: OptionType[];
}
type RangeOption =
  | "today"
  | "last 7 days"
  | "last month"
  | "last year"
  | "year to date"
  | "all time";
type RangeLabel =
  | "Today"
  | "Last 7 Days"
  | "Last Month"
  | "Last Year"
  | "Year to Date"
  | "All Time";
interface RangeData {
  label: RangeLabel;
  value: RangeOption;
}
const rangeOptions: RangeData[] = [
  {
    label: "Today",
    value: "today"
  },
  {
    label: "Last 7 Days",
    value: "last 7 days"
  },
  {
    label: "Last Month",
    value: "last month"
  },
  {
    label: "Last Year",
    value: "last year"
  },
  {
    label: "Year to Date",
    value: "year to date"
  },
  {
    label: "All Time",
    value: "all time"
  }
];
export const DateRangePickerInput = ({
  size = "large",
  className,
  onSelect,
  ...props
}: DateRangeProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  let extraClass = "";
  if (className) {
    extraClass =
      typeof className === "object" ? className.join(" ") : className;
  }
  const rangeOptionsToUse = props.customRangeOptions || rangeOptions;
  return (
    <div className={`flex ${extraClass}`}>
      {!props.hideRangeOptions ? (
        <Dropdown
          className={`calendar-date-selector calendar-date-selector-${size}`}
          size={size}
          defaultValue={rangeOptionsToUse[0]}
          options={rangeOptionsToUse}
          onChange={onSelect}
        />
      ) : null}

      <DateRangePicker
        {...props}
        clearIcon={null}
        format="MM / dd / yyyy"
        calendarIcon={<Icon name="chevron-down" size="21" />}
        dayPlaceholder=" DD "
        yearPlaceholder=" YYYY"
        rangeDivider={<Icon name="arrow-right" size="12" />}
        // @ts-ignore
        formatShortWeekday={(_, date: Date) =>
          ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
        }
        monthPlaceholder=" MM "
        next2Label={null}
        prev2Label={null}
        className={`picker-${size} picker-${isCalendarOpen ? "open" : "close"}`}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
      />
    </div>
  );
};

export default DateRangePicker;
